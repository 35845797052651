import React, { useState } from 'react';

import rewfoot from '../../assets/img/rewfoot.svg';
import logo_color from '../../assets/img/logo_color.png';
import burgerOpen from '../../assets/img/burgerOpen.png';
import closeBurger from '../../assets/img/closeBurger.png';

import { Link } from 'react-router-dom';
import PricesSwiper from '../../components/PricesSwiper';

import cx from 'classnames';
import Translation from '../../components/Translation';

import LanguagesSelect from '../../components/LanguagesSelect';
import flagUk from '../../assets/img/flags/flag_uk.svg';

const prices = [
  {
    id: 'prices-swiper-key-1',
    title: 'pricing_p_1-title',
    price: '199€',
    posting: 'pricing-for_30_days',
    total: '199€',
    href: 'https://app.rewivi.com/sign-in/',
  },
  {
    id: 'prices-swiper-key-2',
    title: 'pricing_p_2-title',
    price: '189€',
    posting: 'pricing-for_30_days',
    total: '1.890€',
    href: 'https://app.rewivi.com/sign-in/',
  },
  {
    id: 'prices-swiper-key-3',
    title: 'pricing_p_3-title',
    price: '179€',
    posting: 'pricing-for_30_days',
    total: '4.475€',
    href: 'https://app.rewivi.com/sign-in/',
  },
];

const Prices = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleBurger = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="pricing_container">
      <aside className="nav_left">
        <div className="nav__logo">
          <Link to="/">
            <img src={rewfoot} alt={'rewfoot'} />
          </Link>
          <a
            href="https://war.ukraine.ua/support-ukraine/"
            target={'_blank'}
            rel="noreferrer"
            className={'rw_logo_flag_uk'}>
            <img src={flagUk} alt="ukraine" />
          </a>
        </div>
        <ul className="nav__psmenu">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className="nav__psprofile">
          <i></i>
          <span></span>
        </div>
      </aside>
      <header className="pricing">
        <div className="block">
          <div className="rw_logo">
            <div>
              <Link to="/">
                <img src={logo_color} alt="logo_color" />
              </Link>
            </div>
          </div>
          <div className="pssearch"></div>
          <div className="menu">
            <ul className={cx('list', isOpen && 'open')} id="list_b">
              <li className="pricing">
                <Link to="/pricing">
                  <Translation id={'landing_header_pricing'}>Pricing</Translation>
                </Link>
              </li>
              <li className="contact">
                <a href="mailto:support@rewivi.com" id="to-contact">
                  <Translation id={'landing_header_contact'}>Contact</Translation>
                </a>
              </li>
              <li className="us">
                <Link to="#">
                  <Translation id={'landing_heare_about'}>About Us</Translation>
                </Link>
              </li>
              <li className="tAndc">
                <Link to="/terms">
                  <Translation id={'landing_header_terms'}>Terms & Condition</Translation>
                </Link>
              </li>
              <li className="privacy">
                <Link to="#">
                  <Translation id={'landing_header_privacy_policy'}>Privacy Policy</Translation>
                </Link>
              </li>
              <li className="line"></li>
              <li className="login">
                <a href="https://app.rewivi.com/sign-in">
                  <Translation id={'landing_header_login'}>Login</Translation>
                </a>
              </li>
              <li className="sign_up">
                <a href="https://app.rewivi.com/sign-up">
                  <Translation id={'landing_header_sign_up'}>Sign Up</Translation>
                </a>
              </li>
              <li>
                <LanguagesSelect />
              </li>
            </ul>
            <div className={cx('burger', isOpen && 'open')} onClick={handleBurger}>
              <img src={isOpen ? closeBurger : burgerOpen} alt="burgerOpen" />
            </div>
          </div>
        </div>
      </header>
      {/*<div class="main_title">*/}
      {/*  <h2>Pricing</h2>*/}
      {/*</div>*/}
      <div className="content_price">
        <div className="card_price">
          <div className="titles_change">
            <h1>
              <Translation id={'pricing_title'}>Pricing</Translation>
            </h1>
            {/*<span class="des active">Deserved plan</span>*/}
            {/*<span class="prep">Prepaid plan</span>*/}
          </div>

          <div className="wrapper_swipper chooses active">
            <PricesSwiper prices={prices} />
          </div>
          {/*<div className="wrapper_swipper nub">*/}
          {/*  <div className="swiper-container mySwiper">*/}
          {/*    <div className="swiper-wrapper">*/}
          {/*      <div className="swiper-slide chose_card">*/}
          {/*        <div className="camp">10 Campaigns</div>*/}
          {/*        <div className="holder_info">*/}
          {/*          <h2 className="euro">90€ + VAT each</h2>*/}
          {/*          <p className="posting">Job posting for 30 days</p>*/}
          {/*          <div className="card_line"></div>*/}
          {/*          <h3 className="gr_info">80€ + VAT each</h3>*/}
          {/*          <h4 className="gr_prol">prolongation for 30 days</h4>*/}
          {/*        </div>*/}
          {/*        <button className="btn_chose">Choose</button>*/}
          {/*      </div>*/}
          {/*      <div className="swiper-slide chose_card">*/}
          {/*        <div className="camp">25 Campaigns</div>*/}
          {/*        <div className="holder_info">*/}
          {/*          <h2 className="euro">85€ + VAT each</h2>*/}
          {/*          <p className="posting">Job posting for 30 days</p>*/}
          {/*          <div className="card_line"></div>*/}
          {/*          <h3 className="gr_info">75€ + VAT each</h3>*/}
          {/*          <h4 className="gr_prol">prolongation for 30 days</h4>*/}
          {/*        </div>*/}
          {/*        <button className="btn_chose">Choose</button>*/}
          {/*      </div>*/}
          {/*      <div className="swiper-slide chose_card">*/}
          {/*        <div className="camp">50 Campaigns</div>*/}
          {/*        <div className="holder_info">*/}
          {/*          <h2 className="euro">80€ + VAT each</h2>*/}
          {/*          <p className="posting">Job posting for 30 days</p>*/}
          {/*          <div className="card_line"></div>*/}
          {/*          <h3 className="gr_info">70€ + VAT each</h3>*/}
          {/*          <h4 className="gr_prol">prolongation for 30 days</h4>*/}
          {/*        </div>*/}
          {/*        <button className="btn_chose">Choose</button>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className="swiper-pagination"></div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div class="contact_us">*/}
          {/*  <span>For individual quote contact us: <span>sales@rewivi.com</span></span>*/}
          {/*</div>*/}
          <div className="big_line"></div>
          <Translation id={'pricing_contact'} dangerouslySetInnerHTML className="contact_us">
            {/*Purchased campaigns are valid 36 months <br />*/}
            {/*<br />*/}
            {/*For individual pricing offers contact us: <a href="mailto:sales@rewivi.com">sales@rewivi.com</a>*/}
          </Translation>
          <div className="foot">
            <h2>What is Deserved plan?</h2>
            <ul>
              <li>Suitable for small companies with low numbers of recruiting campaigns per year.</li>
              <li>When recruiting needs of clients increase, more purchases lead to price discount.</li>
              <li>When recruiting needs of clients increase, more purchases lead to price discount.</li>
            </ul>
          </div>
          <div className="foot_prepaid">
            <h2>What is Prepaid plan?</h2>
            <ul>
              <li>
                Suitable for mid sized and big companies or recruitment agencies with high numbers of recruiting
                campaigns per year.
              </li>
              <li>
                Clients may purchase a number of campaigns that he plans to conduct in the foreseeable future at a
                discounted price
              </li>
              <li>Unlimited validity of the purchased campaign</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prices;
