import login1 from '../../assets/img/1login.png';
import imgChoose2 from '../../assets/img/2imgChoose.png';
import imgChoose3 from '../../assets/img/3imgChoose.png';
import imgChoose4 from '../../assets/img/4imgChoose.png';
import imgChoose5 from '../../assets/img/5imgChoose.png';

export const tabs = [
  {
    id: 'tab-key-0',
    title: 'homepage_tabs-register',
    src: login1,
  },
  {
    id: 'tab-key-1',
    title: 'homepage_tabs-ask_questions',
    src: imgChoose2,
  },
  {
    id: 'tab-key-2',
    title: 'homepage_tabs-collect_responses',
    src: imgChoose3,
  },
  {
    id: 'tab-key-3',
    title: 'homepage_tabs-create_shortlist',
    src: imgChoose4,
  },
  {
    id: 'tab-key-4',
    title: 'homepage_tabs-share_shortlist',
    src: imgChoose5,
  },
];

export const faqs = [
  {
    id: 'faq-key-1',
    isOpened: false,
    height: 0,
    title: 'homepage_faq_q_1-title',
    description: 'homepage_faq_q_1-text',
  },
  {
    id: 'faq-key-2',
    isOpened: false,
    height: 0,
    title: 'homepage_faq_q_2-title',
    description: 'homepage_faq_q_2-text',
  },
  {
    id: 'faq-key-3',
    isOpened: false,
    height: 0,
    title: 'homepage_faq_q_3-title',
    description: 'homepage_faq_q_3-text',
  },
  {
    id: 'faq-key-4',
    isOpened: false,
    height: 0,
    title: 'homepage_faq_q_4-title',
    description: 'homepage_faq_q_4-text',
  },
];
