import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

import { get, set } from 'lockr';

import constants from '../constants';
import axios from 'axios';

const { SUPPORTED_LNGS, API_URL } = constants;

const i18n = require('i18next');

if (!get('lng')) {
  const lng = navigator.language === 'uk' || navigator.language === 'ru' ? 'uk' : 'en';
  set('lng', lng);
}

const lng_version = get('lng-version') || new Date().getTime();

i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: get('lng'),
    supportedLngs: SUPPORTED_LNGS,
    preload: SUPPORTED_LNGS,
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: true,
    },
    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      // backends: [HttpBackend],
      backendOptions: [
        {
          expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
          versions: { en: lng_version, uk: lng_version },
        },
        {
          loadPath: (lng) => {
            return {
              path: `${API_URL}/api/translations?group=landing`,
              lng: lng,
            };
          },
          allowMultiLoading: false,
          request: async function (options, url, payload, callback) {
            const response = await axios.get(url.path, {
              headers: { 'x-localization': url.lng },
            });
            // const version = response.headers['x-localization-ver'];
            // if (version) set('lng-version', version);
            // console.log('version', response.headers['x-localization-ver']);
            // const response = await fetch(url.path, {
            //   headers: { 'x-localization': url.lng },
            // });
            // console.log('response', response.headers);
            // const version = response.headers.get('x-localization-ver');
            // if (version) set('lng-version', version);
            const data = response.data;
            callback(null, {
              status: response.status,
              data: data.data,
            });
          },
        },
      ],
    },
  });

export default i18n;
