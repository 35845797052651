import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { get, set } from 'lockr';

import logo_color from '../../../assets/img/logo_color.png';
import burgerOpen from '../../../assets/img/burgerOpen.png';
import closeBurger from '../../../assets/img/closeBurger.png';
import closeBar from '../../../assets/img/close_opacity.svg';
import flagUk from '../../../assets/img/flags/flag_uk.svg';

import cx from 'classnames';
import LanguagesSelect from '../../LanguagesSelect';
import Translation from '../../Translation';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [ukrainianIdentica, setUkrainianIdentica] = useState(!!!get('ukrainian_identica_hide'));
  const handleBurger = () => {
    setIsOpen((prev) => !prev);
  };
  const handleCloseBar = () => {
    set('ukrainian_identica_hide', new Date());
    setUkrainianIdentica(false);
  };
  return (
    <header className="header">
      <div className="block">
        <div className="rw_logo">
          <div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link to="/">
              <img src={logo_color} alt="logo_color" />
            </Link>
            <a
              href="https://war.ukraine.ua/support-ukraine/"
              target={'_blank'}
              rel="noreferrer"
              className={'rw_logo_flag_uk'}>
              <img src={flagUk} alt="ukraine" />
            </a>
          </div>
        </div>
        <div className="menu">
          <ul className={cx('list', isOpen && 'open')} id="list_b">
            <li className="pricing">
              <Link to="/pricing">
                <Translation id={'landing_header_pricing'}>Pricing</Translation>
              </Link>
            </li>
            <li className="contact">
              <a href="mailto:support@rewivi.com" id="to-contact">
                <Translation id={'landing_header_contact'}>Contact</Translation>
              </a>
            </li>
            <li className="us">
              <Link to="#">
                <Translation id={'landing_heare_about'}>About Us</Translation>
              </Link>
            </li>
            <li className="tAndc">
              <Link to="/terms">
                <Translation id={'landing_header_terms'}>Terms & Condition</Translation>
              </Link>
            </li>
            <li className="privacy">
              <Link to="#">
                <Translation id={'landing_header_privacy_policy'}>Privacy Policy</Translation>
              </Link>
            </li>
            <li className="line"></li>
            <li className="login">
              <a href="https://app.rewivi.com/sign-in">
                <Translation id={'landing_header_login'}>Login</Translation>
              </a>
            </li>
            <li className="sign_up">
              <a href="https://app.rewivi.com/sign-up">
                <Translation id={'landing_header_sign_up'}>Sign Up</Translation>
              </a>
            </li>
            <li>
              <LanguagesSelect />
            </li>
          </ul>
          <div className={cx('burger', isOpen && 'open')} onClick={handleBurger}>
            <img src={isOpen ? closeBurger : burgerOpen} alt="burgerOpen" />
          </div>
        </div>
      </div>
      {ukrainianIdentica && (
        <div className={'header_ukrainian_identica'}>
          <a href="https://war.ukraine.ua/support-ukraine/" target={'_blank'} rel="noreferrer">
            We support the people of Ukraine against Russia’s full-scale invasion
          </a>
          <button onClick={handleCloseBar}>
            <img src={closeBar} alt={'close'} />
          </button>
        </div>
      )}
    </header>
  );
};

export default Header;
