import React from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import Translation from '../Translation';

const PricesSwiper = ({ prices }) => {
  return (
    <>
      <Swiper
        className={'swiper-container'}
        slidesPerView={'auto'}
        pagination={{ clickable: true }}
        modules={[Pagination]}>
        {prices.map((item) => (
          <SwiperSlide className={'chose_card'} key={item.id}>
            <div className="camp">
              <Translation id={item.title} />
            </div>
            <div className="holder_info">
              <h2 className="euro">
                {item.price} <Translation id={'pricing-each'}>{/*each*/}</Translation>
              </h2>
              <p className="posting">
                <Translation id={item.posting} />
              </p>
              <div className="card_line"></div>
              <h3 className="gr_info">
                {item.total} <Translation id={'pricing-total'}>{/*total*/}</Translation>
              </h3>
              {/*<h4 class="gr_prol">prolongation for 30 days</h4>*/}
            </div>
            <a href={item.href} className="btn_chose">
              <Translation id={'pricing-button'}>{/*Choose*/}</Translation>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default PricesSwiper;
