import React, { useState } from 'react';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Homepage from '../../pages/Homepage';
import Prices from '../../pages/Prices';
import Terms from '../../pages/Terms';

import languageContext from '../../context/languageContext';
import { get } from 'lockr';

const { Provider } = languageContext;

const router = createBrowserRouter([
  {
    path: '/',
    element: <Homepage />,
  },
  {
    path: '/pricing',
    element: <Prices />,
  },
  {
    path: '/terms',
    element: <Terms />,
  },
]);

const Routers = () => {
  const [lng, setLng] = useState(get('lng'));
  // const [lng, setLng] = useState('uk');
  return (
    <Provider
      value={{
        lng,
        setLng,
      }}>
      <RouterProvider router={router} />;
    </Provider>
  );
};

export default Routers;
