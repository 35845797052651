// APP CONSTATNS

const constants = {
  APP_NAME: 'Rewivi',

  API_URL: process.env.REACT_APP_APP_URL,

  BUGSNAG_KEY: '',
  GOOGLE_MAPS_KEY: '',

  DEFAULT_LANGUAGE: 'en',
  SUPPORTED_LNGS: ['en', 'uk'],
  LNGS_NAMES: {
    en: {
      en: 'English',
      uk: 'Англійська',
    },
    uk: {
      en: 'Ukrainian',
      uk: 'Українська',
    },
  },
};

export default constants;
