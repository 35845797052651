import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import rewfoot from '../../assets/img/rewfoot.svg';
import logo_color from '../../assets/img/logo_color.png';
import burgerOpen from '../../assets/img/burgerOpen.png';
import closeBurger from '../../assets/img/closeBurger.png';
import cx from 'classnames';
import LanguagesSelect from '../../components/LanguagesSelect';
import Translation from '../../components/Translation';
import flagUk from '../../assets/img/flags/flag_uk.svg';

const Terms = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleBurger = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="terms_container">
      <aside className="nav_left">
        <div className="nav__logo">
          <Link to="/">
            <img src={rewfoot} alt={'rewfoot'} />
          </Link>
          <a
            href="https://war.ukraine.ua/support-ukraine/"
            target={'_blank'}
            rel="noreferrer"
            className={'rw_logo_flag_uk'}>
            <img src={flagUk} alt="ukraine" />
          </a>
        </div>
        <ul className="nav__psmenu">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className="nav__psprofile">
          <i></i>
          <span></span>
        </div>
      </aside>
      <header className="pricing">
        <div className="block">
          <div className="rw_logo">
            <div>
              <Link to="/">
                <img src={logo_color} alt="logo_color" />
              </Link>
            </div>
          </div>
          <div className="pssearch"></div>
          <div className="menu">
            <ul className={cx('list', isOpen && 'open')} id="list_b">
              <li className="pricing">
                <Link to="/pricing">
                  <Translation id={'landing_header_pricing'}>Pricing</Translation>
                </Link>
              </li>
              <li className="contact">
                <a href="mailto:support@rewivi.com" id="to-contact">
                  <Translation id={'landing_header_contact'}>Contact</Translation>
                </a>
              </li>
              <li className="us">
                <Link to="#">
                  <Translation id={'landing_heare_about'}>About Us</Translation>
                </Link>
              </li>
              <li className="tAndc">
                <Link to="/terms">
                  <Translation id={'landing_header_terms'}>Terms & Condition</Translation>
                </Link>
              </li>
              <li className="privacy">
                <Link to="#">
                  <Translation id={'landing_header_privacy_policy'}>Privacy Policy</Translation>
                </Link>
              </li>
              <li className="line"></li>
              <li className="login">
                <a href="https://app.rewivi.com/sign-in">
                  <Translation id={'landing_header_login'}>Login</Translation>
                </a>
              </li>
              <li className="sign_up">
                <a href="https://app.rewivi.com/sign-up">
                  <Translation id={'landing_header_sign_up'}>Sign Up</Translation>
                </a>
              </li>
              <li>
                <LanguagesSelect />
              </li>
            </ul>
            <div className={cx('burger', isOpen && 'open')} onClick={handleBurger}>
              <img src={isOpen ? closeBurger : burgerOpen} alt="burgerOpen" />
            </div>
          </div>
        </div>
      </header>
      <div className="main_title">
        <h2>Terms & Conditions</h2>
      </div>
      <div className="content_terms">
        <div className="card_terms">
          <div className="title">Terms & Conditions</div>
          <div className="terms_cond">
            <div className="before_first">
              <div className="text">
                This document contains the Terms and Conditions applicable to the services offered by NS Inventions s.
                r.o. on the rewivi.com website. You state that you have read and understand the conditions set forth
                herein and that:
                <ul>
                  <li>1. You are a person eligible to enter into contractual relationships.</li>
                  <li>
                    2. You assume all the obligations arranged here. If you act on behalf of a legal person, you declare
                  </li>
                  <li>3. You are at least 18 (eighteen) years old.</li>
                  <li>
                    4. If you have doubts or lack of clarity regarding content or meaning of this document, consult the
                    NS Inventions s. r.o.team via rewivi.com website in writing before you start using our services to
                    resolve them.
                  </li>
                </ul>
              </div>
            </div>
            <div className="wrapper_ol">
              <ul className="main_ol">
                <li>
                  1. The parties
                  <div className="li_wrapper_parties">
                    <div>
                      The rewivi.com website services provider is NS Inventions s. r.o. (hereinafter referred as NSI),
                      with registration number (IČO) 53 347 773 and address in Eurovea Central I., Pribinova 4, 811 09
                      Bratislava, Slovak Republic, a limited liability company registered in the County Court of
                      Bratislava, section Sro, entry 148066/B.
                    </div>
                    <div>
                      The service User, is a natural person or legal person, who has accepted the present Terms and
                      Conditions.
                    </div>
                  </div>
                </li>
                <li>
                  2. Definitions
                  <div className="def">
                    <div>
                      To avoid any doubts and misinterpretation of this Terms and Conditions, the following words are
                      given the meaning described here:
                    </div>
                    <div>
                      <ul>
                        <li>1. User: The natural or legal person who contracts with NS Inventions s. r.o.</li>
                        <li>
                          2. Services: online video interview services and other rewivi.com website functions provided
                          by NS Inventions s. r.o. and contracted by the User.
                        </li>
                        <li>
                          3. Service Provider: NS Inventions s. r.o. is the legal person that provides or performs the
                          rewivi.com website Services.
                        </li>
                        <li>
                          4. Candidate: A natural person who is interviewed by the User through the rewivi.com website
                          Services provided by NS Inventions s. r.o.
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  3. Scope of application of the Terms and Conditions
                  <div className="scopeOf">
                    These Terms and Conditions shall apply to the NS Inventions s. r.o. Services provided via rewivi.com
                    website and used by the User.
                  </div>
                </li>
                <li>
                  4. Requirements for the contracting and use of the Services
                  <div className="requrements">
                    <div>The requirements to be able to use Service are:</div>
                    <div>
                      <ul>
                        <li>1. Accept the present rewivi.com website Terms and Conditions.</li>
                        <li>2. To be eligible to enter into contractual relationships.</li>
                        <li>
                          3. In order to contract a rewivi.com website Service, it is necessary to have a device with
                          Internet access. Such access to the Internet may generate a consumption of connection data and
                          may entail an expense for the User who is solely responsible for such consumption. The Users
                          understand that if the device from which they want to contract a Service has no internet
                          connection, they can not make use of the Services.
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  5. User
                  <div className="user">
                    <div>
                      The User must use the Services solely for his/her own use and must register on rewivi.com website
                      in order to use the Services.
                    </div>
                    <div>
                      The User is responsible for maintaining the confidentiality of the account data and password, as
                      well as for restricting access to his/her Internet access device. Likewise, the User is
                      responsible for maintaining the confidentiality of the personal data of the Candidates who are
                      contacted through the use of the Services. To the extent permitted by applicable law, the User is
                      solely responsible for maintaining under its control the account access data and understands and
                      accepts that all operations carried out with its username and password are binding.
                    </div>
                    <div>
                      The User can not use the Services for purposes other than those contemplated in this Terms of Use
                      and Contract.
                    </div>
                    <div>
                      NSI may deny access to the Service, terminate any account and delete or modify any data in the
                      event that the User violates applicable law, this Terms and Conditions or any other applicable
                      terms of use or policies.
                    </div>
                  </div>
                </li>
                <li>
                  6. Process of contracting the Services:
                  <div className="contract">
                    <div>
                      The contracting process will be carried out in English and Russian languages. The steps will be
                      the following ones for the contracting of the Services:
                    </div>
                    <div>
                      <ul>
                        <li>1. Send registration request using the registration form at rewivi.com</li>
                        <li>
                          2. Confirmation of the account registration using the link sent to the registered email
                          address.
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  7. Price and payment methods.
                  <div className="price">
                    <div>NSI services provided via rewivi.com website are paid services for the User.</div>
                    <div>Prices and payment methods are published at rewivi.com/pricing</div>
                    <div>
                      The User must purchase the selected service and pay for it before he/she is entitled to use it.
                    </div>
                  </div>
                </li>
                <li>
                  8. Limitation of liability. Exclusion of warranties.
                  <div className="limit">
                    <div className="dts">8.1. Content of the Services</div>
                    <div className="cont_div">
                      The Services offered by NS Inventions s. r.o. via rewivi.com website are intended for explicit and
                      specific uses and purposes. NS Inventions s. r.o. will not be held responsible in the event that
                      the User's expectations of the Services are frustrated, and other uses or purposes have been
                      attributed which have not been fulfilled by the end of the Services.
                    </div>
                    <div className="dts">8.2. Devices and browsers</div>
                    <div className="device_div">
                      NS Inventions s. r.o. is not responsible for the possible damages that could be produced by the
                      fact of using devices with defective access to the Internet or versions of browsers not updated,
                      or as a consequence of the bad functioning of the device or browser.
                    </div>
                  </div>
                </li>
                <li>
                  9.Intellectual and industrial property
                  <div className="intel">
                    <div>
                      The intellectual and industrial property rights over the rewivi.com website, its contents
                      (including but not limited to texts, documents, photographs, graphics, images, icons and their
                      design) are the property of NS Inventions s. r.o. and none of the intellectual or industrial
                      property rights existing over them may be understood to have been assigned to the User.
                    </div>
                    <div>
                      When technically possible, in the event that the User provides any type of opinion, assessment or
                      content through the Service, he/she declares to be the holder of the rights over these contents or
                      to have the necessary authorisation or licence from their owner and expressly declares that he/she
                      does not infringe the rights of any third party. The User assigns to NS Inventions s. r.o., free
                      of charge, the rights of public communication, reproduction, distribution and transformation for
                      use in rewivi.com website.
                    </div>
                    <div>
                      The User accepts that NS Inventions s. r.o. will keep a copy of the video recordings of the
                      interview with each Candidate on rewivi.com, also that NS Inventions s. r.o. holds the rights of
                      image, public communication, reproduction, distribution and transformation over such copy for the
                      worldwide territorial scope and for the maximum term of protection foreseen by the applicable
                      intellectual property legislation.
                    </div>
                  </div>
                </li>
                <li>
                  10. Data protection
                  <div className="dat_protect">
                    <div className="dts">10.1. Privacy Policy</div>
                    <div className="mult_divs">
                      <div>
                        The collection of personal data provided by the User to NS Inventions s. r.o. is subject to the
                        following Privacy Policy.
                      </div>
                      <div>
                        Controller of the data collected through rewivi.com website is: NS Inventions s. r.o., with
                        registration number (IČO) 53 347 773 and address in Eurovea Central I., Pribinova 4, 811 09
                        Bratislava, Slovak Republic, a limited liability company registered in the County Court of
                        Bratislava, section Sro, entry 148066/B. and contact email address: support@rewivi.com
                      </div>
                    </div>
                    <div className="div_ol">
                      <div>The personal data provided to NS Inventions s. r.o. shall be used to:</div>
                      <div>
                        <ul>
                          <li>
                            1. Enable to upkeep, develop and manage the business relationship entered into for
                            contracting products and/or services through the rewivi.com website. The data processed for
                            such purpose will be stored for as long as the business relationship lasts and when it ends,
                            the storage term and the statute of limitations stipulated by Law.
                          </li>
                          <li>
                            2. Perform statistical analysis with the personal data provided by the User. The data
                            processed for this purpose will be kept for the legally stipulated periods of conservation
                            and prescription of responsibilities.
                          </li>
                          <li>
                            3. To keep the User informed of NS Inventions s. r.o. services and news by digital means.
                            The data processed for such purpose shall be stored until the User withdraws consent. The
                            User may expressly indicate if he/she authorises such data processing or not by ticking the
                            box enabled for this purpose on the Website.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="text_bg">
                      The legal basis for personal data processing is: a) where the user is a natural person, the
                      consent and/or the execution of the agreement to which the data subject is a party; b) where the
                      user represents a legal person, regarding the data of the contact people, the legitimate interest
                      of contacting the legal person thereby. In the latter case, the user undertakes to inform the
                      contact people of the contents of this clause. The legal basis for the processing of personal data
                      for statistical analysis is the legitimate interest of NS Inventions s. r.o. The data may be
                      communicated to the following third-party recipients: Candidates who require it for the provision
                      of Services, Public Authorities to comply with legal obligations and financial institutions.
                      Furthermore, they may be communicated to the following processors: providers of electronic
                      communications and online office automation, hosting, SaaS services such as CRM/ERP, agency,
                      accounting, auditing and lawyers. NS Inventions s. r.o. may transfer personal data to Processors
                      located in the United States of America that subscribe to the Privacy Shield scheme and that have
                      an Adequacy Decision from the European Commission (Commission Implementing Decision (EU)
                      2016/1250). The User may, at any time, exercise his/her right to access, rectification, deletion,
                      limitation, objection or data portability by means of an email addressed to support@rewivi.com or
                      through a letter sent to NS Inventions s. r.o., Eurovea Central I., Pribinova 4, 811 09
                      Bratislava, Slovak Republic. In processing data, the legitimation for which is consent, the User
                      shall have the right to withdraw such consent at any moment. The data subject has the right to
                      lodge a complaint with the supervisory authority.
                    </div>
                    <div className="dts">10.2. Processor clause</div>
                    <div className="text_bg_2">
                      Object: To regulate the relationship between the User, as Data Controller, and NS Inventions s.
                      r.o., as Data Processor, for the purposes of complying with the provisions of Article 28 of
                      Regulation (EU) 2016/679. Duration: The validity of this clause shall be equal to that of the
                      provision of the Services contracted. Nature and purpose of the treatment: The treatment of the
                      data will be carried out solely and exclusively for the purpose of providing the Services. Type of
                      personal data and categories of interested parties: Unless the User specifies any modification,
                      the data that may be subject to processing are non-sensitive data relating to Candidates.
                      Obligations and rights of the User: a) Give or allow NS Inventions s. r.o. access to the data
                      specified in this clause; b) Carry out an privacy impact assessment on the protection of personal
                      data of the processing operations to be carried out by NS Inventions s. r.o., when applicable; c)
                      Carry out the corresponding prior consultations; d) Ensuring, prior to and throughout the
                      processing, that NS Inventions s. r.o. complies with Regulation (EU) 2016/679; e) Supervising the
                      processing, including the performance of inspections and audits; f) Facilitating the right to
                      information at the time of data collection; g) Complying with the other obligations that
                      Regulation (EU) 2016/679 establishes for the Controller. Obligations of NS Inventions s. r.o.: a)
                      Process personal data only following the User's documented instructions, including the respect to
                      transfers of personal data, unless there is the obligation to do so by virtue of the law of the
                      Union or of the Member States; in such case, NS Inventions s. r.o. will inform the User of this
                      legal requirement prior to processing, unless such law prohibits it for important reasons of
                      public interest; b) Guarantee that the persons authorized to process personal data have undertaken
                      to respect confidentiality; c) Take all necessary measures in accordance with Article 32 of
                      Regulation (EU) 2016/679; d) Do not subcontract the processing of the data to other processors,
                      without the prior written authorization of the User. When NS Inventions s. r.o. has recourse to
                      another processor to carry out certain processing activities on behalf of the User, NS Inventions
                      s. r.o. shall impose on this other processor, by contract, the same data protection obligations as
                      those stipulated in this clause. If this other party fails to comply with its data protection
                      obligations, NS Inventions s. r.o. shall remain fully liable to the User for the performance of
                      the other party's obligations; by means of this clause, the User authorises NS Inventions s. r.o.
                      to subcontract with the following categories of sub-contractors: hosting and communications
                      providers, management software and technical support. e) Assist the data controller, taking into
                      account the nature of the processing, through appropriate technical and organisational measures,
                      whenever possible, so that the data controller can fulfil its obligation to respond to requests
                      for the exercise of data subjects' rights; f) To help the User to ensure compliance with the
                      obligations established in Articles 32 to 36 of Regulation (EU) 2016/679, taking into account the
                      nature of the processing and the information at the disposal of the data processor; g) Unless
                      otherwise indicated by the User, to delete all personal data once the provision of the Services
                      has ended, as well as to delete existing copies, unless the conservation of personal data is
                      required by virtue of the law of the Union or of the Member States; h) Make available to the User
                      all the information necessary to show compliance with the obligations established in this clause,
                      as well as to allow and contribute to the performance of audits, including inspections, by the
                      User or another auditor authorized by the User; i) Immediately inform the User if, in the opinion
                      of NS Inventions s. r.o., an instruction violates Regulation (EU) 2016/679 or other provisions on
                      data protection of the Union or the Member States; j) Comply with any other obligation that
                      corresponds to it in accordance with current legislation on data protection.
                    </div>
                  </div>
                </li>
                <li>
                  11. Nullity or ineffectiveness
                  <div className="ineffect">
                    The declaration of total nullity or partial ineffectiveness of any clause included in this Terms of
                    use and contracting shall only affect said clause or the part thereof that is null or ineffective.
                    In the event that such declaration occurs, this Terms of use and contracting shall subsist in
                    everything else, considering the provision declared null or ineffective as not included, except that
                    the clause was essential.
                  </div>
                </li>
                <li>
                  12. Applicable law and jurisdiction
                  <div className="law">
                    These Terms and Conditions shall be governed by and construed in accordance with law of the Slovak
                    Republic. Both parties agree to submit any dispute arising from this Terms of use and contracting to
                    the Bratislava municipal court corresponding to the domicile of NS Inventions s. r.o.
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
