import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import languageContext from '../../context/languageContext';

const Translation = ({ id, dangerouslySetInnerHTML, ...rest }) => {
  const { t } = useTranslation();
  const { lng } = useContext(languageContext);
  if (!!dangerouslySetInnerHTML) {
    return (
      <div
        {...rest}
        dangerouslySetInnerHTML={{
          __html: t(`${id}.text`, {
            lng,
          }),
        }}
      />
    );
  }
  return t(`${id}.text`, {
    lng,
  });
};

export default Translation;
