import React from 'react';
import rewfoot from '../../../assets/img/rewfoot.png';
import linkdn from '../../../assets/img/linkdn.png';
import fb from '../../../assets/img/fb.png';
import { Link } from 'react-router-dom';
import Translation from '../../Translation';

const Footer = () => {
  return (
    <footer>
      <div className="wrapper__">
        <div className="footer_blocks">
          <div className="block except">
            <div className="site_logo">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#">
                <img src={rewfoot} alt="logo_color" />
              </a>
            </div>

            <div className="cont">
              <div className="comp_address">
                <Translation id={'landing_footer_address'}>
                  Eurovea Central I., Pribinova 4, 811 09 Bratislava, Slovak Republic
                </Translation>
              </div>
            </div>
          </div>

          {/*<div class="block list_1">*/}
          {/*  <div class="P_As">*/}
          {/*    <div class="pricing item">*/}
          {/*      <a href="#">Pricing</a>*/}
          {/*    </div>*/}
          {/*    <div class="about item">*/}
          {/*      <a href="#">About Us</a>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="block list_2">
            <div className="policy">
              <div className="pricing item">
                <Link to="/pricing">
                  <Translation id={'landing_header_pricing'}>Pricing</Translation>
                </Link>
              </div>
              <div className="terms item">
                <Link to="/terms">
                  <Translation id={'landing_header_terms'}>Terms & Conditions</Translation>
                </Link>
              </div>
              {/*<div class="privacy item">*/}
              {/*  <a href="#">Privacy Policy</a>*/}
              {/*</div>*/}
            </div>
          </div>

          <div className="block socials">
            <div className="linkdn">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#">
                <img src={linkdn} alt="linkdn" />
              </a>
            </div>
            <div className="linkdn">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#">
                <img src={fb} alt="fb" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
