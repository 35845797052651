import React, { useState } from 'react';
import Layout from '../../components/Layout';
import cx from 'classnames';

import videoGirl from '../../assets/img/videoGirl.png';
import rocket from '../../assets/img/rocket.png';
import money from '../../assets/img/money.png';
import protect from '../../assets/img/protect.png';
import answer from '../../assets/img/answer.png';
import settings from '../../assets/img/settings.png';
import protect_big from '../../assets/img/protect_big.png';
import think from '../../assets/img/think.png';
import look from '../../assets/img/look.png';
import arrowBig from '../../assets/img/arrowBig.png';

import searchB from '../../assets/img/searchB.png';
import open_toggle from '../../assets/img/open_toggle.png';

import { Collapse } from 'react-collapse';

import { tabs, faqs } from './config';
import Translation from '../../components/Translation';

const Homepage = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [faqsList, setFaqsList] = useState(faqs);

  const handleChangeTab = (e) => {
    setCurrentTab(+e.target.getAttribute('data-imageset'));
  };

  const handleClickFaq = (e, index) => {
    const result = faqsList.map((item, i) => {
      if (index === i) {
        return { ...item, isOpened: !item.isOpened };
      }
      return item;
    });
    setFaqsList(result);
  };

  return (
    <Layout>
      <div className="container" id="container">
        <section className="sctn_head">
          <div className="wrapper__">
            <div className="blocks">
              <div className="text_block">
                <div className="title">
                  <Translation id={'homepage_hero_title'}>{/*See people, not resumes with Rewivi.com*/}</Translation>
                </div>

                <Translation id={'homepage_hero_description'} dangerouslySetInnerHTML={true} className={'enhance'}>
                  {/*
                  Enhanced candidate evaluation based on visual input

                  Read More

                  Imagine the comfort of having all the candidates at the same moment in the same place - whenever the time is right for you
                  */}
                </Translation>

                <a href="https://app.rewivi.com/sign-up/" className="btn_start">
                  <Translation id={'homepage_hero_get-started'}>{/*Get Started*/}</Translation>
                </a>
              </div>
            </div>
          </div>
          <div className="video">
            <img src={videoGirl} alt="video" />
            {/*<img src="../img/play.png" alt="play" class="play" />*/}
          </div>
        </section>

        <div className="benefits">
          <div className="benefit">
            <div className="logo rocket_e">
              <img src={rocket} alt="roket" className="roket" />
            </div>
            <div className="block_text">
              <div className="title">
                <Translation id={'homepage_hero_list_faster-title'} />
              </div>
              <div className="text">
                <Translation id={'homepage_hero_list_faster-text'} />
              </div>
            </div>
          </div>
          <div className="benefit">
            <div className="logo">
              <img src={money} alt="money" className="money" />
            </div>
            <div className="block_text">
              <div className="title">
                <Translation id={'homepage_hero_list_time-title'} />
              </div>
              <div className="text">
                <Translation id={'homepage_hero_list_time-text'} />
              </div>
            </div>
          </div>
          <div className="benefit">
            <div className="logo">
              <img src={protect} alt="protect" className="protect" />
            </div>
            <div className="block_text">
              <div className="title">
                <Translation id={'homepage_hero_list_health-title'} />
              </div>
              <div className="text">
                <Translation id={'homepage_hero_list_health-text'} />
              </div>
            </div>
          </div>
        </div>

        <section className="answer">
          <div className="wrapper__">
            <div className="info">
              <div className="blocks">
                <div className="title">
                  <div className="img_rocket">
                    <img src={rocket} alt="rocket" />
                  </div>
                  <Translation id={'homepage_speed_up-title'} dangerouslySetInnerHTML className={'text'}>
                    {/*Speed up your interview process using Rewivi.com*/}
                  </Translation>
                </div>
                <div className="block_list">
                  <Translation id={'homepage_speed_up-list'} dangerouslySetInnerHTML>
                    {/*<ul>*/}
                    {/*  <li>Benefit from the possibility to review video replies of candidates at your convenience.</li>*/}
                    {/*  <li>Replay candidate’s answers as many times as needed.</li>*/}
                    {/*  <li>Take advantage of comparing candidates head-to-head in real time.</li>*/}
                    {/*  <li>Benefit from the visual input and see the candidate's body language and overall expression.</li>*/}
                    {/*  <li>Give candidates the opportunity replying to your interview questions by video instantly.</li>*/}
                    {/*  <li>Prepare quality shortlist of candidates based on more complex information.</li>*/}
                    {/*</ul>*/}
                  </Translation>
                </div>
              </div>

              <div className="anser_img">
                <img src={answer} alt="answer" />
              </div>
            </div>
          </div>
        </section>

        <section className="settings">
          <div className="wrapper__">
            <div className="info">
              <div className="anser_img">
                <img src={settings} alt="setting" />
              </div>

              <div className="blocks">
                <div className="title">
                  <div className="img_rocket">
                    <img src={money} alt="money" />
                  </div>
                  <div className="text">
                    <Translation id={'homepage_save_time-title'} dangerouslySetInnerHTML>
                      {/*Save time and money during interview process with Rewivi.com*/}
                    </Translation>
                  </div>
                </div>
                <div className="block_list">
                  <Translation id={'homepage_save_time-list'} dangerouslySetInnerHTML>
                    {/*<ul>*/}
                    {/*  <li>No need to conduct phone interviews or schedule personal interviews.</li>*/}
                    {/*  <li>Review received video responses at your convenience.</li>*/}
                    {/*  <li>Visual input combined with traditional CV significantly accelerates the selection process.</li>*/}
                    {/*  <li>Quit the review process whenever you realize that the candidate doesn’t fit the vacancy.</li>*/}
                    {/*  <li>Streamline internal selection and approval processes</li>*/}
                    {/*</ul>*/}
                  </Translation>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="secure">
          <div className="wrapper__">
            <div className="info">
              <div className="blocks">
                <div className="title">
                  <div className="img_rocket">
                    <img src={protect} alt="money" />
                  </div>
                  <div className="text">
                    <Translation id={'homepage_stay_safe-title'} dangerouslySetInnerHTML className="safe">
                      {/*<b>Stay safe</b> with Rewivi.com*/}
                    </Translation>
                    <p className="blue_text">
                      <Translation id={'homepage_stay_safe-blue_text'}>
                        {/*In today's challenging environment, it is critical to address and minimize the potential health risks.*/}
                      </Translation>
                    </p>
                    <Translation
                      id={'homepage_stay_safe-addtnal_text'}
                      dangerouslySetInnerHTML
                      className="addtnal_text">
                      {/*<b className="bld">Increase personal safety and protect health,</b> during interview process with*/}
                      {/*Rewivi.com*/}
                    </Translation>
                  </div>
                </div>
                <Translation id={'homepage_stay_safe-list'} dangerouslySetInnerHTML className="block_list">
                  {/*<ul>*/}
                  {/*  <li>Get the important first impression without the necessity to meet the candidate in person.</li>*/}
                  {/*  <li>Fully benefit from the home office regime - even during interview process.</li>*/}
                  {/*  <li>Protect your teams from health risks by limiting number of visitors.</li>*/}
                  {/*  <li>Provide safety to candidates. Do not require candidate to travel and meet other people.</li>*/}
                  {/*</ul>*/}
                </Translation>
              </div>

              <div className="anser_img">
                <img src={protect_big} alt="setting" />
              </div>
            </div>
          </div>
        </section>

        <section className="how_work">
          <div className="block">
            <div className="title">
              <Translation id={'homepage_how_it_works-title'}>{/*How it works?*/}</Translation>
            </div>
          </div>
          <div className="wrapper__">
            <div className="cards">
              <div className="card">
                <img src={think} alt="think" />
                <div className="wrapper_content">
                  <div className="title">
                    <Translation id={'homepage_how_it_works_challenges-title'}>
                      {/*Challenges of the conventional recruitment process*/}
                    </Translation>
                  </div>
                  <Translation id={'homepage_how_it_works_challenges-list'} dangerouslySetInnerHTML>
                    {/*<ul>*/}
                    {/*  <li>Rummaging through hundreds of CV’s</li>*/}
                    {/*  <li>Phone interviews take time</li>*/}
                    {/*  <li>Organizing interviews takes time</li>*/}
                    {/*  <li>Adjusting schedules takes time</li>*/}
                    {/*  <li>Applicants cancelling appointments, coming late or too early</li>*/}
                    {/*  <li>No chance to compare candidates in real time</li>*/}
                    {/*  <li>You have to continue the interview, even if the candidate doesn't fit the vacancy</li>*/}
                    {/*  <li>Candidates might be a health risk to your organisation</li>*/}
                    {/*</ul>*/}
                  </Translation>
                </div>
              </div>

              <div className="card">
                <img src={look} alt="look" />
                <div className="wrapper_content">
                  <div className="title">
                    <Translation id={'homepage_how_it_works_how_does-title'}>
                      {/*How does Rewivi.com help?*/}
                    </Translation>
                  </div>
                  <Translation id={'homepage_how_it_works_how_does-list'} dangerouslySetInnerHTML>
                    {/*<ul>*/}
                    {/*  <li>Applicants answer your specific interview questions or demonstrate their skills on video</li>*/}
                    {/*  <li>*/}
                    {/*    You conduct a candidate's review in line with your desired schedule without time consuming and*/}
                    {/*    complex planning process*/}
                    {/*  </li>*/}
                    {/*  <li>*/}
                    {/*    You are given the possibility to compare different candidates in real time one by one, or*/}
                    {/*    head-to-head*/}
                    {/*  </li>*/}
                    {/*  <li>*/}
                    {/*    Unlike a written CV, you can also evaluate the nonverbal expressions and communication of the*/}
                    {/*    candidate*/}
                    {/*  </li>*/}
                    {/*  <li>*/}
                    {/*    Using Rewivi, you minimize the human interactions during the initial phases of the interviewing*/}
                    {/*    process*/}
                    {/*  </li>*/}
                    {/*  <li>Stress free environment</li>*/}
                    {/*</ul>*/}
                  </Translation>
                </div>
                <div className="img_arrow">
                  <img src={arrowBig} alt="arrowBig" />
                </div>
                <Translation id={'homepage_tabs-title'} dangerouslySetInnerHTML className="start_using">
                  {/*Start using <span>Rewivi.com</span>*/}
                  {/*<br />*/}
                  {/*in 5 simple steps:*/}
                </Translation>
              </div>
            </div>
          </div>
        </section>

        <section className="computer">
          <div className="wrapper__">
            <div className="holder">
              <div className="monitor">
                <div className="inner_img">
                  <img src={tabs[currentTab].src} alt="monitor" />
                </div>
              </div>
              <div className="block_tabs">
                <ul className="tabs tabs_js">
                  {tabs.map((tab, index) => (
                    <li
                      key={tab.id}
                      className={cx('tab', currentTab === index && 'active')}
                      data-imageset={index}
                      onClick={handleChangeTab}>
                      <span>{index + 1}.</span> <Translation id={tab.title} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="what_is">
          <div className="wrapper__">
            <div className="title">
              <Translation id={'homepage_what_is_rewivi-title'}>{/*What is Rewivi.com?*/}</Translation>
            </div>
            <Translation id={'homepage_what_is_rewivi-text'} dangerouslySetInnerHTML className="text">
              {/*Hiring the right candidate for an open position can be a time-consuming and expensive process. From*/}
              {/*scheduling job interviews with out-of-town candidates to coordinating internal feedback on applicants,*/}
              {/*inefficiencies in the hiring process are costly for businesses. Rewivi.com aim to address some of the*/}
              {/*inefficiencies inherent in scheduling and conducting hiring interviews by using digital devices to make*/}
              {/*virtual interviews possible at any time and from any place. Rewivi.com allows companies to collect*/}
              {/*pre-recorded videos of candidates’ responses to job interview questions, facilitate comparison of*/}
              {/*candidates and internal decision making in the company. Especially with the rise of remote hiring and*/}
              {/*remote work, Rewivi.com has ambition to become a more prominent feature of how many companies hire*/}
              {/*employees. <br />*/}
              {/*Rewivi stands for “Reply with video”. We know that hiring the right candidate for vacancy can be a*/}
              {/*complicated, time-consuming and expensive process. Starting with posting the vacancy and CV screening,*/}
              {/*followed by job interviews with scheduling and rescheduling, up to coordinating the internal feedback on*/}
              {/*selected candidates. Hiring process routine comes at a high cost. This includes time, money and human*/}
              {/*involvement.*/}
            </Translation>
            <div className="read_more_why">Read More</div>
            <div className="text text2">
              Rewivi.com directly addresses the inefficiencies during selecting, scheduling and conducting job
              interviews by using common technologies to enable video interviews possible anytime and anywhere. This
              helps you to focus on decision making, taking away less effective administrative routine from you. <br />
              Rewivi.com is a great tool to enhance the overall effectiveness and speed of your hiring process. <br />
              Rewivi.com directly addresses the inefficiencies during selecting, scheduling and conducting job
              interviews by using common technologies to enable video interviews possible anytime and anywhere. This
              helps you to focus on decision making, taking away less effective administrative routine from you. <br />
              Rewivi.com is a great tool to enhance the overall effectiveness and speed of your hiring process.
              Rewivi.com directly addresses the inefficiencies during selecting, scheduling and conducting job
              interviews by using common technologies to enable video interviews possible anytime and anywhere. This
              helps you to focus on decision making, taking away less effective administrative routine from you. <br />
              Rewivi.com is a great tool to enhance the overall effectiveness and speed of your hiring process.
            </div>
            <div className="smallG"></div>
          </div>
        </section>

        <section className="key_benefits">
          <div className="wrapper__">
            <Translation id={'homepage_key_benefits-title'} dangerouslySetInnerHTML className="keys">
              {/*<span>Key Benefits of </span>*/}
              {/*<span>Rewivi.com</span>*/}
            </Translation>

            <div className="benefits_cards">
              <div className="benefit">
                <div className="flip_card_inner">
                  <div className="flip_card_front">
                    <div className="photo">
                      <img src={searchB} alt="searchB" />
                    </div>
                    <div className="text">
                      <Translation id={'homepage_key_benefits_card-faster_and_more-title'}>
                        {/*Faster and more efficient hiring process*/}
                      </Translation>
                    </div>
                  </div>

                  <div className="flip_card_back">
                    <h3>
                      <Translation id={'homepage_key_benefits_card-faster_and_more-title'}>
                        {/*Faster and more efficient hiring process*/}
                      </Translation>
                    </h3>
                    <p>
                      <Translation id={'homepage_key_benefits_card-faster_and_more-text'}>
                        {/*Rewivi.com helps to arrange job interviews using pre recorded video responses. Applicants record*/}
                        {/*and submit their interview answers at their convenience, whenever it is the right time for them.*/}
                        {/*Hiring managers can review the answers in line with their personal schedule. A recruiter reviews*/}
                        {/*responses of many applicants more quickly, saving significant time when compared to personal or*/}
                        {/*phone interviews.*/}
                      </Translation>
                    </p>
                  </div>
                </div>
              </div>

              <div className="benefit">
                <div className="flip_card_inner">
                  <div className="flip_card_front">
                    <div className="photo">
                      <img src={searchB} alt="searchB" />
                    </div>
                    <div className="text">
                      <Translation id={'homepage_key_benefits_card-enhanced_collaboration-title'}>
                        {/*Enhanced collaboration of the hiring team*/}
                      </Translation>
                    </div>
                  </div>

                  <div className="flip_card_back">
                    <h3>
                      <Translation id={'homepage_key_benefits_card-enhanced_collaboration-title'}>
                        {/*Enhanced collaboration of the hiring team*/}
                      </Translation>
                    </h3>
                    <p>
                      <Translation id={'homepage_key_benefits_card-enhanced_collaboration-text'}>
                        {/*Rewivi.com also helps to facilitate more effective collaboration among involved hiring managers.*/}
                        {/*Interview process usually involves more managers internally. Rewivi.com offers features that*/}
                        {/*enable hiring managers to share applicants’ responses with everyone involved in the process within*/}
                        {/*the company. Videos of applicants interviews are made accessible to them and involved managers can*/}
                        {/*review them at their own convenience. Collecting feedback on candidates becomes faster and more*/}
                        {/*efficient.*/}
                      </Translation>
                    </p>
                  </div>
                </div>
              </div>

              <div className="benefit">
                <div className="flip_card_inner">
                  <div className="flip_card_front">
                    <div className="photo">
                      <img src={searchB} alt="searchB" />
                    </div>
                    <div className="text">
                      <Translation id={'homepage_key_benefits_card-improved_job-title'}>
                        {/*Improved job applicant experience*/}
                      </Translation>
                    </div>
                  </div>

                  <div className="flip_card_back">
                    <h3>
                      <Translation id={'homepage_key_benefits_card-improved_job-title'}>
                        {/*Improved job applicant experience*/}
                      </Translation>
                    </h3>
                    <p>
                      <Translation id={'homepage_key_benefits_card-improved_job-text'}>
                        {/*Rewivi.com creates a candidate centric process. This approach creates a positive impression in*/}
                        {/*front of candidates. With Rewivi.com you may include “welcome video message” and your company’s*/}
                        {/*branding for a professional look. Giving candidates the opportunity to prepare and record their*/}
                        {/*video responses on their own schedule creates positive interview experience.*/}
                      </Translation>
                    </p>
                  </div>
                </div>
              </div>

              <div className="benefit">
                <div className="flip_card_inner">
                  <div className="flip_card_front">
                    <div className="photo">
                      <img src={searchB} alt="searchB" />
                    </div>
                    <div className="text">
                      <Translation id={'homepage_key_benefits_card-expanded_pool-title'}>
                        {/*Expanded applicant pool*/}
                      </Translation>
                    </div>
                  </div>

                  <div className="flip_card_back">
                    <h3>
                      <Translation id={'homepage_key_benefits_card-expanded_pool-title'}>
                        {/*Expanded applicant pool*/}
                      </Translation>
                    </h3>
                    <p>
                      <Translation id={'homepage_key_benefits_card-expanded_pool-text'}>
                        {/*Allowing candidates to record their responses anytime and anywhere maximise the opportunity to*/}
                        {/*apply. For hard hires, your chances to find the right candidates increase. With growing usage of*/}
                        {/*the home office, Rewivi.com video interviews might attract also quality candidates who might not*/}
                        {/*otherwise be able to participate in a conventional interview process due to the candidate’s*/}
                        {/*location or schedule conflict.*/}
                      </Translation>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="btn_start_ben">
              <a href="https://app.rewivi.com/sign-up/" className="btn">
                <Translation id={'homepage_key_benefits_button'}>{/*Get Started*/}</Translation>
              </a>
            </div>
          </div>
        </section>

        <section className="why_use">
          <div className="wrapper__">
            <Translation id={'homepage_faq_title'} dangerouslySetInnerHTML className="title">
              {/*Why Use <span>Rewivi.com</span>*/}
            </Translation>
            <div className="why_blocks">
              {faqsList.map((q, index) => (
                <div
                  className={cx('w_block', 'w_block1', q.isOpened && 'open')}
                  key={q.id}
                  onClick={(e) => {
                    handleClickFaq(e, index);
                  }}>
                  <div className="title_block">
                    <Translation id={q.title} />
                  </div>
                  <Collapse isOpened={q.isOpened}>
                    <p>
                      <span>
                        <Translation id={q.description} />
                      </span>
                    </p>
                  </Collapse>
                  {/*<p>*/}
                  {/*  <span>{q.description}</span>*/}
                  {/*</p>*/}
                  <div className="btn_toggle btn_toggle1">
                    <img src={open_toggle} alt="open_toggle" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="pre_footer">
          <Translation id={'homepage_start_now-title'} dangerouslySetInnerHTML>
            {/*<h2 className="pre_footer_title">Start now to upgrade your recruitment process</h2>*/}
            {/*<h3>to an innovative, faster, more precise and safer experience</h3>*/}
          </Translation>
          <div className="pre_btn_container">
            <a href="https://app.rewivi.com/sign-up/" className="pre_btn">
              <Translation id={'homepage_start_now-button'}>{/*Get Started*/}</Translation>
            </a>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Homepage;
