import React, { useContext } from 'react';
import * as flags from '../../utils/flags';
import { Select } from 'antd';
import constants from '../../constants';
import languageContext from '../../context/languageContext';
import { set } from 'lockr';

const { SUPPORTED_LNGS, LNGS_NAMES } = constants;

const { Option } = Select;

const LanguagesSelect = () => {
  const { lng, setLng } = useContext(languageContext);

  const handleChangeLanguage = (value) => {
    setLng(value);
    set('lng', value);
  };

  return (
    <Select
      value={lng}
      style={{ width: 150 }}
      size="large"
      showArrow={false}
      popupClassName="custom-select-dropdown"
      className={'language-select'}
      onChange={handleChangeLanguage}>
      {SUPPORTED_LNGS.map((l) => (
        <Option key={`language-select__option-${l}`} value={l} className={'language-select__option'}>
          <img src={flags[l]} alt="" />
          {LNGS_NAMES[l][lng]}
        </Option>
      ))}
    </Select>
  );
};

export default LanguagesSelect;
